.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.60);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;


  // Mobile Menu Logo
  .mobile-logo {
    padding-bottom: 30px;
    padding-top: 40px;
    display: block;
    text-align: center;
    background-color: rgba(#1CB9C8, 0.1);

    svg {
      max-width: 185px;
    }
  }

  // Menu Close Button
  .vs-menu-toggle {
    border: none;
    position: absolute;
    right: -20px;
    top: 10px;
    padding: 0;
    line-height: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    z-index: 1;
    background-color: $theme-color;
    color: $white-color;
    border-radius: 50%;

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }

  .vs-menu-area {
    width: 100%;
    max-width: 310px;
    background-color: #fff;
    border-right: 3px solid $theme-color;
    height: 100%;
    position: relative;
    left: -110%;
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
    z-index: 1;
  }

  &.vs-body-visible {
    opacity: 1;
    visibility: visible;

    .vs-menu-area {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}


// Mobile Menu
.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;

  ul {
    margin: 0;
    padding: 0 0;

    li {
      border-bottom: 1px solid #fdedf1;
      list-style-type: none;

      li:first-child {
        border-top: 1px solid #fdedf1;
      }

      a {
        display: block;
        position: relative;
        padding: 12px 0;
        line-height: 1;
        font-size: 16px;
        text-transform: capitalize;
        color: $title-color;

        &:before {
          content: '\f105';
          font-family: $icon-font;
          position: relative;
          left: 0;
          top: 0;
          margin-right: 10px;
          display: inline-block;
        }
      }

      &.active {
        >a {
          color: $theme-color;

          &:before {
            transform: rotate(90deg);
          }
        }
      }

      ul {
        li {
          padding-left: 20px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .menu-item-has-children {
      position: relative;

      >ul {
        display: none;
      }
      
      >.vs-mean-expand {
        position: absolute;
        right: 0;
        top: 8px;
        font-weight: 400;
        font-size: 12px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        display: inline-block;
        text-align: center;
        background-color: $smoke-color;
        color: $title-color;
        box-shadow: 0 0 20px -8px rgba(#ad8858, 0.5);
        border-radius: 50%;
        cursor: pointer;

        &:before {
          content: '\f067';
          font-family: $icon-font;
        }
      }

      &.active {
        >.vs-mean-expand:before {
          content: '\f068';
        }
      }
    }
  }

  >ul {
    padding: 0 40px;

    >li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}


.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: #F2F6FF;
  color: $theme-color;
  border-radius: 5px;

  &.style-text,
  &.style-text-white {
    width: auto;
    height: auto;
    background-color: transparent;
    color: $title-color;
    font-size: 20px;

    i {
      margin-right: 10px;
    }
  }

  &.style-text-white {
    color: $white-color;
  }
}


@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu>ul {
    padding: 0 20px;
  }
}