@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:  url('Exo-Regular.woff2') format('woff2'),
        url('Exo-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:  url('Exo-Medium.woff2') format('woff2'),
        url('Exo-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:  url('Exo-SemiBold.woff2') format('woff2'),
        url('Exo-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;  
  src:  url('Exo-Bold.woff2') format('woff2'),
        url('Exo-Bold.woff') format('woff');
}