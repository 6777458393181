.slick-track>[class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: $sm) {
  .row:not([class*="gx-"]) {
    --bs-gutter-x: 30px;
  }
}

.gy-30 {
  --bs-gutter-y: 30px;
}

@media (min-width: $lg) {
  .gx-60 {
    --bs-gutter-x: 60px;
  }
  .gx-70 {
    --bs-gutter-x: 70px;
  }
}

@media (min-width: $ml) {
  .gx-30 {
    --bs-gutter-x: 30px;
  }

  .gx-20 {
    --bs-gutter-x: 20px;
  }

  .gx-40 {
    --bs-gutter-x: 40px;
  }
}