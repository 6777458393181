.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
  background-color: $smoke-color;
  position: relative;
  margin-bottom: 40px;
  border: 6px;

  select,
  input {
    height: 55px;
    border: none;
    background-color: #fff;
    padding-left: 20px;
    font-weight: 400;
  }
}

.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
  margin: -0.07em 0 35px 0;
  font-family: $title-font;
}

.widget {
  .search-form {
    position: relative;
    display: flex;    

    input {
      flex: 1;
    }

    button {
      border: none;
      background-color: $theme-color;
      color: $white-color;
      height: 55px;
      width: 55px;
      line-height: 55px;
      font-size: 17px;

      &:hover {
        background-color: $title-color;
        color: $white-color;
      }
    }
  }
}

.wp-block-tag-cloud,
.tagcloud {
  a {
    display: inline-block;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 9.5px 16.5px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: $title-color;
    background-color: $smoke-color;
    border-radius: 4px;

    &:hover {
      background-color: $theme-color;
      color: $white-color !important;
    }
  }
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;

  a {
    background-color: $white-color;
    color: $title-color;
  }
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }

  .media-img {
    margin-right: 20px;
    width: 80px;

    img {
      width: 100%;
      border-radius: 4px;
    }
  }

  .post-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 5px 0;
  }

  .recent-post-meta {

    a {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
      color: $body-color;

      i {
        margin-right: 5px;
        color: $theme-color
      }
    }
  }
}


.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;

  span[class*="wp-calendar-nav"] {
    position: absolute;
    top: 8px;
    left: 20px;
    font-size: 14px;
    color: $title-color;
    font-weight: 500;
    z-index: 1;

    a {
      color: inherit;
    }
  }


  span.wp-calendar-nav-next {
    left: auto;
    right: 20px;
  }

  caption {
    caption-side: top;
    text-align: center;
    color: $title-color;
    background-color: #f1dbbe;
  }

  th {
    font-size: 14px;
    padding: 5px 5px;
    border: none;
    text-align: center;
    border-right: 1px solid #fff;
    color: #01133c;
    font-weight: 500;

    a {
      color: inherit;
    }
  }


  td {
    font-size: 14px;
    padding: 5px;
    color: #01133c;
    border: 1px solid #ededed;
    text-align: center;
    background-color: transparent;
    transition: all ease 0.4s;
  }

  #today {
    color: $theme-color;
    background-color: $white-color;
    border-color: #ededed;
  }

  thead {
    background-color: #f6f6f6;
  }

  .wp-calendar-table {
    margin-bottom: 0;
  }


  .wp-calendar-nav {
    .pad {
      display: none;
    }
  }

}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .gallery-thumb {
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      border-radius: 4px;
    }
  }
}

.widget_shopping_cart {
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .mini_cart_item {
    position: relative;
    border-bottom: 1px solid $border-color;
    padding: 0 0 32px 110px;
    margin: 0 0 27px 0;
    min-height: 90px;
  }

  .remove {
    position: absolute;
    right: 0;
    top: 0;
    color: $title-color;
    line-height: 1;
    font-size: 18px;

    &:hover {
      color: $theme-color;
    }
  }

  .img {
    position: absolute;
    left: 0;
    top: 3px;
    width: 90px;
    height: 90px;
    display: inline-block;
    border: 1px solid $border-color;
  }

  .product-title {
    font-size: 14px;
    color: $title-color;
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
  }

  .amount {
    display: block;
    font-weight: 600;
    color: $title-color;
    font-size: 16px;
  }

  .quantity {
    display: inline-flex;
    margin-top: 12px;
  }

  .qut-btn {
    border: 1px solid $border-color;
    background-color: transparent;
    display: inline-block;
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 12px;
    z-index: 1;
    position: relative;

    &:hover {
      background-color: $title-color;
      border-color: transparent;
      color: $white-color;
    }
  }

  .qty-input {
    border: 1px solid $border-color;
    text-align: center;
    width: max-content;
    min-width: 40px;
    font-size: 12px;
    padding: 0;
    height: 25px;
    margin: 0 -1px;
  }

  .subtotal {
    float: right;
    text-align: right;
    font-size: 12px;
    margin-top: 19px;

    >span:not(.amount) {
      color: $body-color;
      font-weight: 300;
    }

    .amount {
      font-size: 12px;
      display: inline-block;
    }
  }

  .total {
    color: $title-color;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 25px;
    font-size: 18px;

    .amount {
      font-size: inherit;
      display: inline-block;
      color: $theme-color;
    }
  }

  .vs-btn {
    border: 1px solid $title-color;
    color: $title-color;
    margin-right: 10px;
    text-transform: capitalize;
    height: 50px;
    line-height: 47px;
    padding: 0 35px;
    background-color: $title-color;
    color: $white-color;
    font-weight: 400;

    &:hover {
      background-color: transparent;
      color: $title-color;
    }
  }

  .buttons {
    margin: 0;
  }

}

.sidebar-area {
  margin-bottom: -10px;

  ul.wp-block-latest-posts {
    margin-bottom: 0;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .newsletter-form {
    button {
      width: 100%;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      height: 60px;
      margin-top: 10px;
    }
  }

  .widget .wp-block-search {
    margin-bottom: 0;
  }

  .wp-block-group__inner-container h2 {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 20px;
    margin-top: -0.07em;
  }

  ol.wp-block-latest-comments {
    padding: 0;
    margin: 0;

    li {
      line-height: 1.5;
      margin: 0 0 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}


.recent-product {
  display: flex;

  .product-title {
    font-size: 16px;
  }
}

.widget-workhours {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    background-color: $white-color;
    padding: 14.5px 20px;
    margin: 0 0 15px 0;
    font-family: $title-font;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-right: 10px;
    }

    a {
      color: inherit;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.banner-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#071A3E, 0.70);
  }
  
  &__title {
    color: $white-color;
    position: relative;
    z-index: 1;
    max-width: 200px;
    margin: -0.3em auto 25px auto;
  }
}


@include lg {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }

  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .recent-post {
    .post-title {
      font-size: 14px;
      line-height: 22px;
    }

    .recent-post-meta a {
      font-size: 12px;
    }
  }

  .widget-workhours {
    li {
      padding: 14.5px 20px;
      font-size: 14px;
    }
  }

  
}


@include md {
  .sidebar-area {
    padding-top: 30px;
  }

  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

@include sm {
  .contact-widget {
    .icon {
      --icon-size: 30px;
      --icon-font-size: 12px;
      top: 5px;
    }

    .contact {
      padding: 0 0 0 45px;
      margin: 0 0 20px 0;
      border-bottom: none;
    }
  }

  .sidebar-gallery {
    gap: 10px;
  }
}

@include xs {
  .widget {
    padding: 30px 20px;
  }
}