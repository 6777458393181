.fadein {
  --animation-name: fadein;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideindown;
}

.slideinright {
  --animation-name: slideindown;
}

.animated {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-name: var(--animation-name);
}

.ripple-animation {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.40;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}


@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
