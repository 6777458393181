/* @import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0.5,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px !important;
} */

p.Services {
  @apply mb-0 lg:pb-2;
}

h2.logo {
  @apply 2xl:text-5xl lg:pt-5 md:text-3xl sm:text-2xl sm:pt-2 text-xs font-[500] text-text_gray mb-0;
}

img.logo {
  @apply 2xl:w-[80px] sm:w-[50px] w-[25px]  object-cover;
}

.HTyellow {
  @apply bg-yellow border-none  lg:rounded-b sm:rounded-b rounded-b lg:rounded-3xl sm:rounded-2xl rounded-lg font-semibold leading-[1] lg:py-4 py-1.5 lg:px-4 px-0;
}

.HTgray {
  @apply bg-bg_gray  border-none  lg:rounded-b sm:rounded-b rounded-b lg:rounded-3xl sm:rounded-2xl rounded-lg font-semibold leading-[1] lg:py-4 py-1.5 lg:px-4 px-0;
}

.Tyellow {
  @apply bg-yellow border-none  font-semibold leading-[1] lg:pt-4  lg:mt-3;
}
.Tgray {
  @apply bg-bg_gray border-none  font-semibold leading-[1] lg:pt-4  lg:mt-3;
}

.TLyellow {
  @apply bg-light_yellow border-none  leading-[1] lg:pt-4  lg:mt-3;
}
.TLgray {
  @apply bg-bg_light_gray border-none   leading-[1] lg:pt-4  lg:mt-3;
}
.TLgrayLong {
  @apply bg-bg_light_gray  border-none  leading-[1]  lg:pt-4  lg:mt-3  2xl:text-[22px] lg:text-[18px] md:text-[12px] sm:text-[10px]  mobile:text-[6px] 2xl:px-14 px-1;
}

.SMSServices {
  @apply lg:max-h-[175px] 2xl:mx-0 mx-auto sm:max-h-[120px]  max-h-[80px]  p-4;
}

.SMSheader {
  @apply lg:text-[35px] sm:text-[25px] text-[18px] font-bold lg:pb-4 sm:pb-2 mb-1 lg:text-nowrap;
}

.SMSContent {
  @apply mx-3 lg:text-[20px] sm:text-[17px] text-[9px];
}

.container1 {
  @apply container;
}
.scroll {
  display: block;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}
