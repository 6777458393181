// Primary Button 
.vs-btn {
  position: relative;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: $theme-color;
  color: $white-color;
  font-family: $body-font;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 19px 30.5px;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;

  &::after,
  &::before {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $title-color;
    transition: all ease .5s;
    z-index: -1;
    border-radius: inherit;
  }

  &:before {
    transform: rotateX(90deg);
  }
  
  &:after {
    transform: rotateY(90deg);
  }

  >i {
    margin-left: 8px;
  }


  &.style2 {
    background-color: $white-color;
    color: $theme-color;
  }

  &.style4,
  &.style3 {
    background-color: $secondary-color;
    color: $theme-color;
  }

  &.style3 {
    padding: 14px 23.5px;
  }

  &.style5 {
    background-color: #0844BC;
    color: $white-color;
    width: 150px;
    padding: 10px;
    margin: 5px;

  }

  &.style6 {
    background-color: $title-color;
    color: $white-color;

    &:after,
    &::before {
      background-color: $theme-color;
    }
  }


  &:hover {
    color: $white-color;

    &:before {
      transform: rotateX(0);
    }

    &:after {
      transform: rotateY(0);
    }
  }
}



// Icon Btn
.icon-btn {
  display: inline-block;
  width: var(--btn-size, 67px);
  height: var(--btn-size, 67px);
  line-height: var(--btn-size, 67px);
  font-size: var(--btn-font-size, 20px);
  background-color: $theme-color;
  color: $white-color;
  border: none;
  text-align: center;
  border-radius: 50%;

  &.style2 {
    border: 2px solid $white-color;
    color: $white-color;
    background-color: transparent;
  }

  &.style4,
  &.style3 {
    --btn-size: 55px;
    background-color: #F2F6FF;
    color: $theme-color;
    border-radius: 5px;
  }

  &.style4 {
    background-color: $white-color;
  }

  &.style5 {
    background-color: $white-color;
    color: $title-color;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);

    &:hover {
      background-color: $theme-color;
      color: $white-color;
      box-shadow: none;
    }
  }

  &:hover {
    background-color: $title-color;
    color: $white-color;
  }
}


// Play Button 
.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  padding: 0;

  >i {
    display: inline-block;
    width: var(--icon-size, 124px);
    height: var(--icon-size, 124px);
    line-height: var(--icon-size, 124px);
    font-size: var(--icon-font-size, 1.5em);
    text-align: center;
    background-color: $theme-color;
    color: $white-color;
    border-radius: 50%;
    z-index: 1;
    transition: all ease 0.4s;
  }


  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $theme-color;
    @extend .ripple-animation;
    z-index: -1;
    border-radius: 50%;
    transition: all ease 0.4s;
  }

  &:after {
    animation-delay: 2s;
  }

  &.style3,
  &.style2 {
    i {
      --icon-size: 60px;
      border-radius: 5px;
      --icon-font-size: 18px;
    }
  }

  &.style2 {
    &:after,
    &:before {
      border-radius: 5px;
    }
  }

  &.style3 {
    i {
      background-color: rgba(#0E59F2, 0.70);
    }

    &:after,
    &::before {
      display: none;
    }
  }

  &.style4 {
    --icon-size: 120px;
    --icon-font-size: 30px;

    &:after,
    &:before,
    i {
      background-color: $white-color;
      color: $theme-color;
    }
  }

  &.style5 {
    
    i {
      background-color: $white-color;
      color: $theme-color;
      --icon-size: 55px;
      --icon-font-size: 18px;
      border-radius: 5px; 
    }

    &:after,
    &::before {
      display: none;
    }
  }

  &:hover {
    &:after,
    &::before,
    i {
      background-color: $title-color;
      color: $white-color;
    }
  }

}


// Link Button
.link-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;

  i {
    margin-left: 7px;
    font-size: 0.9rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: $theme-color;
    transition: all ease 0.4s;
  }

  &:hover {
    color: $theme-color;

    &::before {
      width: 100%;
    }
  }

}




// Scroll To Top
.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  padding: 0;
  border: none;
  border-radius: 50%;

  i {
    display: inline-block;
    background-color: $theme-color;
    color: $white-color;
    text-align: center;
    font-size: 16px;
    width: var(--btn-size, 50px);
    height: var(--btn-size, 50px);
    line-height: var(--btn-size, 50px);
    z-index: 2;
    border-radius: inherit;
    position: relative;
    transition: all ease 0.8s;
  }

  &:before {
    content: '';
    position: absolute;
    left: var(--extra-shape, -6px);
    top: var(--extra-shape, -6px);
    right: var(--extra-shape, -6px);
    bottom: var(--extra-shape, -6px);
    background-color: rgba(255, 255, 255, 1);
    border-radius: inherit;
    z-index: 1;
  }

  &:focus,
  &:hover {
    i {
      background-color: $title-color;
      color: $white-color;
    }
  }

  &.show {
    bottom: 120px;
    opacity: 1;
    visibility: visible;
  }

  @include sm {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;

    &.show {
      bottom: 15px;
    }
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;

  &.show {
    bottom: 60px;
    opacity: 1;
    visibility: visible;
  }
}

@include lg {
  .play-btn.style4 {
    --icon-size: 80px;
    --icon-font-size: 20px;
  }
}


@include sm {
  .play-btn {
    --icon-size: 60px;
  }

  .scrollToTop {
    right: 20px;

    &.show {
      bottom: 20px;
    }
  }
}