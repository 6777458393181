.footer-widget {
  margin-bottom: 40px;

  &,
  .widget {
    padding: 0;
    border: none;
    padding-bottom: 0;
    background-color: transparent;
  }

  .widget_title {
    margin: 0 0 27px 0;
  }

  &.widget_nav_menu {
    margin-bottom: 40px;  
    
    >ul {
      margin: 0;
    }

    a {
      width: max-content;
      display: block;
      border: none;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
      max-width: 100%;
      padding: 0;

      &:hover {
        background-color: transparent;
        color: $theme-color;
      }

      &:before {
        display: none;
      }
    }

    li {
      >span {
        @include equal-size(auto);
        position: relative;
        background-color: transparent;
        color: $body-color;
        line-height: 1;
      }

      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-links {    
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    a {
      margin-bottom: 21px;
    }
  }

  .recent-post {
    max-width: 300px;
  }

}

.footer-logo {
  margin-bottom: 15px;
  display: inline-block;
}

.footer-text {
  margin: -0.3em 0 25px 0;
  max-width: 285px;
}

.footer-media {
  display: flex;
  justify-content: center;
  border-right: 1px solid #ffce8c;
  padding: 60px 0 60px 0;

  &:last-child {
    border-right: none;
  }

  &.style2 {
    border: none;

    .media-icon {
      background-color: transparent;
      border: 1px solid #35496F;
    }
  }


  .info-media  {
    display: flex;
  }  

  .media-icon {
    width: 85px;
    height: 85px;
    line-height: 85px;
    text-align: center;
    background-color: #ffce8c;
    color: $white-color;
    font-size: 30px;
    margin: 0 25px 0 0;
    display: inline-block;
    border-radius: 5px;
  }

   .media-label {
    color: $white-color;
    font-size: 16px;
    font-weight: 600;
    font-family: $title-font;
    text-transform: uppercase;
    display: block;
    margin: 0 0 5px 0;
  }

  .media-link {
    max-width: 190px;
    color: #8A99B4;
    margin: 0 0 -0.15em 0;

    a {
      color: inherit;

      &:hover {
        color: $theme-color;
      }
    }
  }
}


.footer-number {
  border-top: 1px solid #ffce8c;
  padding: 43px 0 0 0;
  margin: 34px 0 0 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -60px;
    width: 9999px;
    height: 1px;
    border: inherit;
  }

  .info {
    color: $body-color;
    margin: 0;
    line-height: 1;
    display: inline-block;

    &:hover {
      color: $theme-color;
    }
  }
  
  .title {
    margin: 0 0 7px 0;
  }
}

.footer-map {
  max-width: 400px;
  width: 100%;
  
  frame {
    max-width: 100%;
  }
}

.footer-social {
  .social-title {
    font-family: $title-font;
    color: $title-color;
    font-size: 20px;
    display: inline-block;
    margin-right: 20px;
    font-weight: 700;
  }

  a {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    margin: 0 5px 0 0;
    color: $white-color;
    background-color: #231f20;
    text-align: center;
    border-radius: 5px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #656262;
      color: $white-color;
    }
  }
}

.footer-newsletter {
  display: flex;
  
  input {
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    border: 1px solid #ffce8c;
    height: 55px;
    color: $white-color;
    flex: 1;

    @include inputPlaceholder {
      color: $white-color;
    }
  }

  .vs-btn {
    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: $theme-color;
      color: $white-color;
    }

    &:after,
    &::before {
      display: none;
    }
  }
}


.footer-schedule {
  table {
    border: none;
    margin: -0.3em 0 0 0;
  }

  th,
  td {
    padding: 0 0 10px 0;
    color: $body-color;
    border: none;
    font-weight: 400;
  }
}

@include ml {
  .menu-all-pages-container.footer-links {
    width: 330px;
    max-width: 100%;
  }

  .footer-number:before {
    display: none;
  }
}

@include lg{
  .footer-widget {
    margin-bottom: 40px;

    .widget_title {
      font-size: 26px;
    }
  }

  .footer-number {
    padding: 27px 0 0 0;
    margin: 17px 0 0 0;
  }

  .footer-media{
    padding: 0;
    border: none;

    .info-media {      
      display: block;
      text-align: center;
    }
    
    .media-icon {
      margin: 0 0 20px 0;
    }
  }
}


@media (min-width: $md) and (max-width: $ml) {
  .footer-newsletter {
    display: block;

    input {
      border-radius: 5px;
      margin-bottom: 20px;
    }

    .vs-btn {
      border-radius: 5px;
    }
  }
}

@include md {
  .footer-widget {
    .widget_title {
      font-size: 22px;
    }
  }

  // .footer-layout1 {
  //   .footer-widget.widget_nav_menu a {
  //     margin-left: auto;
  //     margin-right: auto;
  //   }
  // }
}

@include sm {
  .footer-widget.widget_nav_menu a {
    font-size: 14px;
  }

  

  .footer-newsletter {
    display: block;

    input {
      border-radius: 5px;
      margin-bottom: 20px;
    }

    .vs-btn {
      border-radius: 5px;
    }
  }

}
