.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;


  .closeButton {
    display: inline-block;
    border: none;
    color: $white-color;
    background-color: $theme-color;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    transform: rotate(0);
    transition: all ease 0.4s;

    &:hover {
      background-color: $title-color;
      color: $white-color;
      transform: rotate(90deg);
    }
  }


  .sidemenu-content {
    background-color: $white-color;
    width: 450px;
    margin-left: auto;
    padding: 40px;
    height: 100%;
    overflow: scroll;
    position: relative;
    right: -500px;
    cursor: auto;
    transition-delay: 1s;
    transition: right ease 1s;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #F5F5F5;
    }

  } 

  .widget {
    padding: 0;
    border: none;
    margin: 0 0 40px 0;
    background-color: transparent;
  }

  .widget_title {
    border-bottom: 1px solid $border-color;
    padding: 0 0 20px 0;
    margin: 0 0 25px 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: all ease 0.8s;

    .sidemenu-content {
      right: 0;
      opacity: 1;
      visibility: visible;
    }
  }

}
